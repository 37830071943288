import React, { useState } from "react";
import { Link } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";

import "./navbar.scss";
import SearchInput from "./search/search-field";
import { removeStopwords, searchFunction } from "../../common/search-fn";
import { useDispatch, useSelector } from "react-redux";
import { getChannelDataHome } from "../../pages/home/homeSlice";
import { setSearchResultsArray } from "../../pages/search/searchSlice";
import { setSearchedItem } from "../../pages/search/searchedItemSlice";

const Navbar = ({ navData = [], searchedItem, searchedStringHome }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const dispatch = useDispatch();
  const channelDataHome = useSelector(getChannelDataHome);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  };
  window.addEventListener("scroll", handleScroll);

  // window.onscroll = () => {
  //   setIsScrolled(window.scrollY > 0);
  //   return () => {
  //     window.onscroll = null;
  //   }
  // }

  // #TODO - remove hard-coded logo
  const navigate = useNavigate();
  const handleSearch = (searchItem) => {
    dispatch(setSearchedItem(searchItem));
    const removedStopWords = removeStopwords(searchItem);
    const filteredArray = searchFunction(removedStopWords, channelDataHome);
    dispatch(setSearchResultsArray(filteredArray));
    navigate("/search");
  };
  return (
    <div className={isScrolled ? "navbar scrolled" : "navbar"}>
      <div className="container">
        <div className="left">
          <Link className="link" to="/">
            <img
              src="https://relytv.com/wp-content/themes/relytv/assets/images/color_logo_transparent.png"
              alt=""
            />
          </Link>

          <span>
            <Link className="link" to="/">
              HOME
            </Link>
          </span>
          {navData.includes("Movie") && (
            <span>
              <Link className="link" to="/movies">
                MOVIES
              </Link>
            </span>
          )}
          {navData.includes("Series") && (
            <span>
              <Link className="link" to="/series">
                SERIES
              </Link>
            </span>
          )}
          {navData.includes("ShortFilm") && (
            <span>
              <Link className="link" to="/shortfilms">
                SHORT FILMS
              </Link>
            </span>
          )}
          {/* <span>
            <Link className="link" to="/"> MY LIST </Link>
          </span> */}
        </div>
        <div className="right">
          {/* <SearchIcon className="icon" /> */}
          <SearchInput handleSearch={handleSearch} />
          {/* <span>KID</span>
          <NotificationsIcon className="icon" /> */}
          <img
            src="https://images.unsplash.com/photo-1494869042583-f6c911f04b4c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80"
            alt="Profile"
          />
          {/* <div className="profile">
            <ArrowDropDownIcon className="icon" />
            <div className="options">
              <span>Settings</span>
              <span>Logout</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
